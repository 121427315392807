import React from "react";
import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import { Helmet } from "react-helmet";
function StudioManual() {
  return (
    <div>
      <Helmet>
        <title>Studio Manual</title>
        <link rel="canonical" href="https://masterwizr.com/studiomanual" />
      </Helmet>
      <Header />
      <ManualContainer
        image={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673455309/Manuals/studio12manual_mnhvfx.png"
        }
      />
    </div>
  );
}
export default StudioManual;
